.MovieTileBar {
  transition: height 0.3s, background 0.3s;
}

.MovieTileBar .MovieHeader {
  margin-bottom: 0%;
  transition: white-space 0.3s, margin-bottom 0.3s;
}
.MovieLink:focus + img + .MovieTileBar {
  height: 40%;
  background: rgba(0, 0, 0, 1);
}

.MovieLink:focus + img + .MovieTileBar .MovieHeader {
  margin-bottom: 3%;
  white-space: normal;
}